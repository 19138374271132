import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function ContactForm() {
  const [isValid, setIsValid] = React.useState(null)

  return (
    <section className="hero is-small is-dark is-bold">
      <div className="hero-body">
        <div className="container">
          <div className="columns">
            <div className="column is-5 is-flex is-flex-direction-column is-justify-content-center">
              <h2 className="title">
                <span role="img" aria-label="letter in postbox emoji">
                  📮
                </span>{" "}
                Contact me
              </h2>
              <h3 className="subtitle is-5">
                If you have an opportunity you think I might be interested in,
                want to collaborate with me or, need something built &mdash;
                fill out the form to reach out.
              </h3>
            </div>
            <div className="column is-1" />
            <div className="column is-6">
              <div className="box contact-form-box">
                <form
                  name="contact"
                  method="post"
                  data-netlify="true"
                  netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <div className="field">
                    <label className="label" htmlFor="name">
                      Name
                    </label>
                    <div className="control">
                      <input
                        autoComplete="name"
                        name="name"
                        className="input"
                        type="text"
                        placeholder="Your name"
                      />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label" htmlFor="email">
                      Email
                    </label>
                    <div className="control has-icons-left has-icons-right">
                      <input
                        name="email"
                        autoComplete="email"
                        onBlur={({ target }) =>
                          setIsValid(
                            target.value.includes("@") &&
                              target.value.includes(".")
                          )
                        }
                        className={`input ${
                          isValid === false ? "is-danger" : ""
                        }`}
                        type="email"
                        placeholder="Your contact email"
                      />
                      <span className="icon is-small is-left">
                        <FontAwesomeIcon icon={["fas", "envelope"]} />
                      </span>
                      {isValid === false && (
                        <span className="icon is-small is-right">
                          <FontAwesomeIcon
                            icon={["fas", "exclamation-triangle"]}
                          />
                        </span>
                      )}
                      {isValid === true && (
                        <span className="icon is-small is-right">
                          <FontAwesomeIcon icon={["fas", "check"]} />
                        </span>
                      )}
                    </div>
                    {isValid === false && (
                      <p className="help is-danger">This email is invalid</p>
                    )}
                  </div>
                  <div class="field">
                    <label class="label" htmlFor="category">
                      Category
                    </label>
                    <div className="select">
                      <select name="category" defaultValue="other">
                        <option value="blog">Guest blog post</option>
                        <option value="youtube">YouTube collaboration</option>
                        <option value="consulting">Consulting</option>
                        <option value="podcast">Podcast</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>

                  <p class="is-hidden">
                    <label>
                      Don’t fill this out if you're human:{" "}
                      <input name="bot-field" />
                    </label>
                  </p>

                  <div className="field">
                    <label className="label" htmlFor="message">
                      Message
                    </label>
                    <div className="control">
                      <textarea
                        name="message"
                        className="textarea"
                        placeholder="Write your message and send it into the beyond"
                      ></textarea>
                    </div>
                  </div>
                  <div className="control">
                    <button type="submit" className="button is-primary">
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
