import React from "react"

export default function Card({
  title,
  link,
  image,
  children,
  customCardClass,
}) {
  return (
    <div
      className={
        customCardClass
          ? customCardClass
          : "column is-flex is-half-tablet is-one-quarter-desktop"
      }
    >
      <a
        className="card is-flex is-flex-direction-column card-hover-effect full"
        style={{ width: "100%" }}
        href={link}
        target="_blank"
        rel="noreferrer"
      >
        <div className="card-image">
          <figure className="image is-16by9 m-0">
            {image ? (
              <img src={image} alt="Post Banner" />
            ) : (
              <div
                style={{
                  backgroundColor: "grey",
                  height: "100%",
                  bottom: 0,
                  left: 0,
                  position: "absolute",
                  right: 0,
                  top: 0,
                }}
              />
            )}
          </figure>
        </div>
        <div className="card-content">
          <div className="content has-text-left">
            <h4>{title}</h4>
            <p>{children}</p>
          </div>
        </div>
      </a>
    </div>
  )
}
