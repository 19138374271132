import React from "react"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/Card"
import DevChallengeHero from "../sections/dev-challenge-hero"
import ABCsForDevsHero from "../sections/abcs-for-devs-hero"
import ContactForm from "../sections/contact-form"
import "./theme.scss"

const IndexPage = ({ data }) => {
  const codingInFramerSeries = [...data.codingInFramerSeries.edges]
  codingInFramerSeries.reverse()

  return (
    <Layout>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <section className="hero section is-block is-relative has-background-white-ter is-medium">
        <div className="container">
          <h2 className="is-size-2">
            <a
              href="https://www.youtube.com/playlist?list=PLRG1hGYAPvla9Gu9GEdNSjRAu7ay7Q9eP"
              className="section-link"
              style={{ color: "#4f1fad" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={["fas", "code"]}
                style={{ maxWidth: "3rem" }}
              />{" "}
              Coding in Framer Series
            </a>
          </h2>
          <p className="mb-6 is-size-4">
            Watch my free tutorial series on how to get started coding in
            Framer. We'll use the popular design and prototyping Framer Web to
            craft beautiful user interfaces but we'll make them more rich and
            interactive with code. I'll take you from your first code component
            in React, smooth animations with Framer Motion and handoff into a
            code project.
          </p>

          <div className="columns is-centered is-variable is-4 is-multiline">
            {codingInFramerSeries.map((x) => (
              <Card
                key={x.node.videoId}
                title={x.node.title}
                image={x.node.thumbnail.url}
                link={`https://www.youtube.com/watch?v=${x.node.videoId}`}
                customCardClass="column is-flex is-half-tablet is-one-third-desktop"
              />
            ))}
          </div>
        </div>
      </section>
      <ABCsForDevsHero />
      <section className="hero section is-block is-relative has-background-white-ter is-medium">
        <div className="container">
          <h2 className="is-size-2">
            <a
              href="https://blog.sethcorker.com"
              className="section-link has-text-danger-dark"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={["fas", "heart"]}
                style={{ maxWidth: "3rem" }}
              />{" "}
              Benevolent Bytes Blog
            </a>
          </h2>
          <p className="mb-6 is-size-4">
            I write articles about React, JavaScript and web development on my
            own blog. Learn more about animations on the web with React
            libraries including Framer Motion or make cool custom animations in
            CSS.
          </p>

          <div className="columns is-centered is-variable is-4">
            {data.allFeedBenevolentBytesBlog.nodes.map((post) => (
              <Card
                key={post.link}
                title={post.title}
                link={post.link}
                image={post.enclosure ? post.enclosure.url : null}
              >
                <p>{post.contentSnippet}</p>
              </Card>
            ))}
          </div>
        </div>
      </section>
      <DevChallengeHero />
      <ContactForm />
      <section className="hero section is-block is-relative has-background-white-ter is-medium">
        <div className="container">
          <h2 className="is-size-2">
            <a
              href="https://www.youtube.com/channel/UCBZseZ5y5P8sK-87Kqj8MJQ"
              className="section-link has-text-danger-dark"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={["fab", "youtube"]}
                style={{ maxWidth: "3rem" }}
              />{" "}
              YouTube: Coding with Seth
            </a>
          </h2>
          <p className="mb-6 is-size-4">
            Watch Coding with Seth and let's build things together, experiment
            with web technologies and craft amazing animations in Framer Motion.
          </p>

          <div className="columns is-centered is-variable is-4 is-multiline">
            {data.allYoutubeVideo.edges.map((x) => (
              <Card
                key={x.node.videoId}
                title={x.node.title}
                image={x.node.thumbnail.url}
                link={`https://www.youtube.com/watch?v=${x.node.videoId}`}
              >
                {x.node.description.slice(0, 220).trim()}&hellip;
              </Card>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    allYoutubeVideo(
      limit: 6
      filter: {
        videoId: {
          nin: [
            "kHVVEfg_5gU"
            "horNo0uyPFA"
            "2Rv3SXqy0p4"
            "mBIi1IP_Khc"
            "zJib3Y7M-co"
            "cbYOSD9DMvw"
          ]
        }
      }
    ) {
      edges {
        node {
          id
          title
          description
          videoId
          publishedAt
          privacyStatus
          channelTitle
          thumbnail {
            url
          }
        }
      }
    }
    codingInFramerSeries: allYoutubeVideo(
      limit: 8
      filter: {
        videoId: {
          in: [
            "kHVVEfg_5gU"
            "horNo0uyPFA"
            "2Rv3SXqy0p4"
            "mBIi1IP_Khc"
            "zJib3Y7M-co"
            "cbYOSD9DMvw"
          ]
        }
      }
    ) {
      edges {
        node {
          id
          title
          videoId
          publishedAt
          channelTitle
          thumbnail {
            url
          }
        }
      }
    }
    allFeedBenevolentBytesBlog(limit: 4) {
      nodes {
        title
        link
        enclosure {
          url
        }
        content {
          encoded
        }
        contentSnippet
      }
    }
  }
`

export default IndexPage
