import React from "react"

export default function DevChallengeHero() {
  return (
    <section className="hero is-regular is-primary is-bold">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">Bored? Need a creative challenge?</h1>
          <h2 className="subtitle">
            Try making something new and letting your creativity flow through
            the confines of a really, <i>really</i>, limited design prompt
            generator.
          </h2>
          <a className="button" href="https://devchallenge.sethcorker.com">
            Visit "Dev Challenge" and start making
          </a>
        </div>
      </div>
    </section>
  )
}
