import React from "react"

export default function ABCsForDevsHero() {
  return (
    <section className="hero is-regular is-danger is-bold">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">Want a little fun this holidays?</h1>
          <h2 className="subtitle">
            Here's a little ABCs website for web developers! It's a little bit
            of fun these holidays, each day until the 26<sp>th</sp> of December,
            a new letter will be out.
          </h2>
          <a
            className="button"
            href="https://abcs-for-web-developers.sethcorker.com"
          >
            Learn your ABCs all over again
          </a>
        </div>
      </div>
    </section>
  )
}
